$color-blue: #0089ff;
$color-lightblue: #f4f8fd;
$color-grey: #5c6671;
$color-lightgrey: #eeeeee;
$color-black: #292929;
$color-darkgrey: #383839;
$color-white: #fff;
$color-green: #00d672;

$line-color: #5c6671;

$font-family-main: 'DINNextLTPro-Light', Helvetica, Arial, sans-serif;
$font-family-header: 'DINNextLTPro-Light', Helvetica, Arial, sans-serif;
