@import './mixins.scss';
@import './variables.scss';

/*
@font-face {
  font-family: "DINNextLTPro-Regular";
  src: url("../fonts/DINNextLTPro-Regular.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "DINNextLTPro-Light";
  src: url("../fonts/DINNextLTPro-Light.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: 'Tangerine';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/tangerine/v17/IurY6Y5j_oScZZow4VOxCZZM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
*/

@font-face {
  font-family: 'DINNextLTPro-Light';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIFsdA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, :after, :before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html, body {
  height: 100%;
}

body {
  font-family: $font-family-main;
  color: $color-black;
  background: #fff;
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

h1, h2, h3, h4 {
  font-family: $font-family-header;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 50px;
}
h2 {
  font-size: 25px;
}
h3 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
h4 {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
}

a {
  &:link, &:visited, &:active, &:hover {
    text-decoration: none;
    color: $color-black;
  }
}
h1, p, .subheader {
  a {
    overflow-wrap: break-word;
    border-bottom-style: dotted;
    border-bottom-width: 1px;
  }
}

img {
  border: 0;
  vertical-align: middle;
}

/*-- section --*/

section {
  width: 100%;
}
@media (min-width: 768px) {
  section {
  }
}

.container {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 20px;
}

/*----*/

.noscript {
  background: rgba(0, 0, 0, 0.85);
  color: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  text-shadow: 0 1px 1px #000;
  padding: 10px;
  z-index: 99999;

  a {
    color: #3498db;

    &:visited {
      color: #3498db;
    }
  }
}

.button, a.button {
  border-radius: 6px;
  background-color: $color-blue;
  border: 1px solid $color-blue;
  color: $color-white;
  padding: 5px 15px 5px 35px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  /*@include button-transition-mixin();*/
  .icon {
    position: absolute;
    stroke: $color-white;
    left: 5px;
    top: 3px;
  }
  &.outlined {
    background-color: transparent;
    color: $color-blue;
    .icon {
      stroke: $color-blue;
    }
  }
}
.button:hover, a.button:hover {
  background-color: rgba(0, 137, 255, 0.8);
  &.outlined {
    color: $color-white;
    .icon {
      stroke: $color-white;
    }
  }
}
.button:focus {
  outline: 0;
}

/* --- dropdown --- */

.dropdown-menu {
  background-color: green;
  color: green;
  box-shadow: none;
}

/* --- header / footer */

header {
  @include section-indent;
  height: 100px;
  position: relative;
  background-color: rgba(238, 238, 238, 0);
  & > .container {
    height: 100%;
    & > .row {
      height: 100%;
      & > .col {
        position: relative;
        height: 100%;
      }
      & > .col:nth-child(1) {
        width: 140px;
        flex: none;
      }
      & > .col:nth-child(2) {
        flex: 1;
        height: 100%;
      }
    }
  }
  #logo {
    position: absolute;
    bottom: 0;
    span {
      display: block;
      white-space: nowrap;
      font-size: 28px;
      font-weight: 600;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none;
    }
  }

  #navigation {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    @media (max-width: 767px) {
      right: 0;
      top: 25px;
    }
    li {
      text-align: right;
      margin-bottom: 10px;
      a {
        font-size: 20px;
      }
      @media (min-width: 768px) {
        float: left;
        text-align: right;
        margin-bottom: 0;
        a {
          padding: 0 0 0 40px;
        }
      }
    }
  }
}

footer {
  width: 100%;
  font-size: 16px;
  padding: 80px 25px 40px 25px;
  text-align: center;
  .sites {
    margin-bottom: 20px;
  }
  .copyright {
    font-size: 12px;
  }
}

#contactUs {
  padding-top: 100px;
  text-align: center;
  a:nth-child(1) {
    margin-bottom: 5px;
  }
}

#cookieBanner {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #fff;
  background-color: $color-darkgrey;
  .container {
    /*max-width: 1200px;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      button {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px 20px;
        color: $color-black;
        border: none;
        background-color: $color-blue;
        margin-left: 30px;
        font-weight: 400;
        cursor: pointer;
        border-radius: 4px;
      }
    }
  }
  a {
    color: #fff;
  }
  p {
    line-height: 1.6;
    font-size: 16px;
  }
}

/* flex */
/*.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.col {
  width: 100%;
  @media (min-width: 768px) {
    width: inherit;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}*/

/* square, circle */
.square, .circle {
  position: relative;
  width: 100%;
  overflow: hidden;
  img {
    display: block;
    /*min-width: 100%;
    min-height: 100%;*/
    margin: auto;
    position: absolute;
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.square:after, .circle:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.circle {
  border-radius: 50%;
}

.chip {
  line-height: 1em;
  white-space: nowrap;
  padding: 0 10px;
  border-radius: 5px;
  &:before {
    content: '# ';
    opacity: .5;
  }
}

.badge {
  line-height: 1.8;
  white-space: nowrap;
  padding: 0 20px;
  border-radius: 25px;
  border-width: 1px;
  border-style: solid;
}
