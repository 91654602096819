/*@mixin section-shadow-mixin() {
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
}*/

@mixin section-inner-shadow-mixin() {
  -moz-box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.5), inset 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.5), inset 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.5), inset 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin button-transition-mixin() {
  -webkit-transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
  -o-transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
}

@mixin section-indent {
  & > .container {
    height: 100%;
    & > .row {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      & > .col {
        width: 100%;
      }
      @media (min-width: 768px) {
        & > .col {
          flex: 1;
          height: 100%;
        }
      }
    }
  }
}

@mixin section-style {
  @include section-indent;
  h2 {
    border-bottom: 1px solid $line-color;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
    text-transform: uppercase;
  }
  .case {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    padding: 20px;
    h4 {
      margin-bottom: 5px;
    }
    .subheader {
      font-size: 16px;
      opacity: 0.7;
      display: block;
      margin-bottom: 15px;
    }
    .pdfIcon {
      fill: none;
      stroke-width: 3;
      stroke: #491EC4;
    }
    .button {
      @media (min-width: 768px) {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
    .badge {
      margin-top: 15px;
      display: inline-block;
      @media (min-width: 768px) {
        position: absolute;
        top: 5px;
        right: 20px;
      }
      .icon {
        width: 20px;
        margin-left: -5px;
        margin-right: 5px;
        position: relative;
        top: 4px;
        path {
          opacity: 0.8;
        }
      }
    }
    p {
      font-size: 16px;
      line-height: 2;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
