@import '../../assets/css/mixins.scss';
@import '../../assets/css/variables.scss';

#landing {
  @include section-indent;
  padding-top: 40px;
  @media (min-width: 768px) {
    padding-top: 0;
    height: calc(100% - 300px);
    min-height: 600px;
  }
  h1 {
    font-style: normal;
    font-size: 36px;
    line-height: 56px;
    @media (min-width: 768px) {
      font-size: 36px;
      line-height: 56px;
    }
    a:nth-child(1) {
      margin-right: 10px;
      &::after {
        content: "";
        display:inline-block;
        position: absolute;
        height:18px;
        width:18px;
        background-size: 18px 18px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBVcGxvYWRlZCB0bzogU1ZHIFJlcG8sIHd3dy5zdmdyZXBvLmNvbSwgR2VuZXJhdG9yOiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgYXJpYS1oaWRkZW49InRydWUiIHJvbGU9ImltZyIgY2xhc3M9Imljb25pZnkgaWNvbmlmeS0tZW1vamlvbmUiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIG1lZXQiPgogIDxnIGZpbGw9IiMwMDg5ZmYiPgogICAgPHBhdGggZD0iTTYxLjIgMjVDNTggMTEuOCA0Ni4yIDIgMzIgMmgtMXYyM2gzMC4yIj4KICAgIDwvcGF0aD4KICAgIDxwYXRoIGQ9Ik0xNyA2QzEwIDEwLjEgNC44IDE2LjkgMi44IDI1SDE3VjZ6Ij4KICAgIDwvcGF0aD4KICAgIDxwYXRoIGQ9Ik0yLjggMzljMiA4LjEgNy4yIDE0LjkgMTQuMiAxOVYzOUgyLjh6Ij4KICAgIDwvcGF0aD4KICAgIDxwYXRoIGQ9Ik0zMSA2MmgxYzE0LjIgMCAyNi05LjggMjkuMi0yM0gzMXYyMyI+CiAgICA8L3BhdGg+CiAgPC9nPgogIDxwYXRoIGQ9Ik02MS4yIDI1SDMxVjJjLTUuMS4yLTkuOSAxLjYtMTQgNHYxOUgyLjhjLS41IDIuMi0uOCA0LjYtLjggN2MwIDIuNC4zIDQuOC44IDdIMTd2MTljNC4xIDIuNCA4LjkgMy44IDE0IDRWMzloMzAuMmMuNS0yLjIuOC00LjYuOC03YzAtMi40LS4zLTQuOC0uOC03IiBmaWxsPSIjZmZlNjJlIj4KICA8L3BhdGg+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;
      }
    }
  }
  h1:nth-child(2) {
    margin-top: 10px;
    @media (min-width: 768px) {
      margin-top: 20px;
    }
  }
  h2 {
    margin-top: 40px;
    opacity: 0.8;
    line-height: 36px;
  }
  & > .container {
    & > .row {
      & > .col {
        position: relative;
      }
    }
  }
  @media (min-width: 768px) {
    & > .container {
      & > .row {
        & > .col:nth-child(1) {
          flex: 1;
          & > div {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        & > .col:nth-child(2) {
          width: 300px;
          flex: none;
          text-align: center;
        }
      }
    }
  }
  #avatar {
    width: 80%;
    display: block;
    margin: 40px auto;
    margin-bottom: 60px;
    @media (min-width: 768px) {
      width: 200px;
      height: 200px;
      position: absolute;
      display: inline;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

#research {
  @include section-style;
  h2 {
    margin-bottom: 0;
  }
  .case {
    background-color: $color-lightblue;
    border-radius: 16px;
    .badge {
      font-size: 16px;
      padding: 0 15px;
      &.elevated {
        color: $color-blue;
        path {
          stroke: $color-blue;
        }
      }
    }
  }
  .chip {
    color: $color-blue;
    background-color: $color-lightblue;
    border-width: 2px;
    text-transform: lowercase;
    padding: 5px 20px;
    margin-right: 10px;
    margin-top: 10px;
    display: inline-block;
  }
}

#projects {
  @include section-style;
  margin-top: 20px;
  padding-top: 20px;
}

#publications {
  @include section-style;
  margin-top: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f1f1f1;
  position: relative;
  h3 {
    position: absolute;
    top: -40px;
  }
}

#teaching {
  @include section-style;
  margin-top: 60px;
  padding-top: 30px;
  h2 {
    margin-bottom: 20px;
  }
  h4 {
    margin-bottom: 0;
    text-transform: none;
  }
  .subheader {
    margin-bottom: 5px;
    display: block;
  }
  & > .container {
    & > .row {
      & > .col:nth-child(2) {
        padding-bottom: 20px;
      }
    }
  }
  @media (min-width: 768px) {
    .subheader {
      margin-bottom: 20px;
    }
    & > .container {
      & > .row {
        & > .col:nth-child(1) {
          flex: 1;
        }
        & > .col:nth-child(2) {
          width: 300px;
          flex: none;
          text-align: right;
        }
      }
    }
  }
}

#contact, #kontakt {
  @include section-style;
  margin-top: 70px;
}
